import { CssBaseline, CssVarsProvider } from '@mui/joy'
import React from 'react'
import { gottit } from '../themes/gottit'
import Wrapper from './Wrapper'

const App = () => {
    return (
        <CssVarsProvider theme={gottit}>
            <CssBaseline />
            <Wrapper />
        </CssVarsProvider>
    )
}

export default App