import React from 'react'
import { trySignOut } from 'actions/security';
import { enqueueSnackbar } from 'notistack';

export const useSignOut = () => {
    const signout = async (token: string) => {
        const response = await trySignOut(token);
        if(!response.is_success) {
            enqueueSnackbar(response.error[0], {variant: "error"});
        }
    }

    return { signout}
}