import { DashboardRounded, GroupRounded, SettingsRounded, SupportRounded } from "@mui/icons-material";
import { hash } from "helpers/String";
import { IMenuItem } from "interfaces/IMenu";

export const defaultTopMenu: IMenuItem[] = [
    {
        id: hash("_dashboard"),
        name: "Dashboard",
        path: "/dashboard",
        icon: <DashboardRounded />,
        children: [],
        divider: false
    }
];

export const defaultBottomMenu: IMenuItem[] = [
    {
        id: hash("_support"),
        name: "Support",
        path: "/support",
        icon: <SupportRounded />,
        children: [],
        divider: false
    },
    {
        id: hash("_settings"),
        name: "Settings",
        path: "/settings",
        icon: <SettingsRounded />,
        children: [],
        divider: false
    },
];