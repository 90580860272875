import React from 'react'
import { Box, List, listItemButtonClasses, Theme } from '@mui/joy'
import { defaultBottomMenu, defaultTopMenu } from './MenuData'
import { IMenuItem } from 'interfaces/IMenu'
import RenderMenu from './RenderMenu'
import { useModuleMenu } from 'core/hooks/useModuleMenu'

const sx = {
    minHeight: 0,
    overflow: 'hidden auto',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    [`& .${listItemButtonClasses.root}`]: {
        gap: 1.5,
    },
}

const Menu = () => {
    const { moduleMenu } = useModuleMenu();

    return (
        <Box sx={sx}>
            <List
                size="sm"
                sx={{
                    gap: 1,
                    '--List-nestedInsetStart': '30px',
                    '--ListItem-radius': (theme: Theme) => theme.vars.radius.sm,
                }}
            >
                {defaultTopMenu.map((m: IMenuItem) => (<RenderMenu menu={m} key={m.id} />))}
                {moduleMenu.map((m: IMenuItem) => (<RenderMenu menu={m} key={m.id} />))}
            </List>

            <List
                size="sm"
                sx={{
                    mt: 'auto',
                    flexGrow: 0,
                    '--ListItem-radius': (theme) => theme.vars.radius.sm,
                    '--List-gap': '8px',
                    mb: 2,
                }}
            >
                {defaultBottomMenu.map((m: IMenuItem) => (<RenderMenu menu={m} key={m.id} />))}
            </List>
        </Box>
    )
}

export default Menu