import React, { useEffect, useState } from 'react'
import { getEnv } from 'helpers/Env';
import { IMenuItem } from 'interfaces/IMenu';
import { defaultBreadcrumbs } from '../../views/layout/private/BreadcrumbNameMap';
import { getItem, getModulesOrDefault } from 'helpers/Storage';


export const useModuleMenu = () => {
    const [moduleMenu, setModuleMenu] = useState<IMenuItem[]>([]);
    const [breadcrumbs, setBreadcrumbs] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        loadModuleMenu()
    }, []);

    const loadModuleMenu = async () => {
        let routes: string[] = getModulesOrDefault();

        let element: any;
        const items: IMenuItem[] = [];

        for (let m of routes) {
            try {
                switch (m) {
                    case getEnv("ERS"): 
                        element = await import(`modules/ers/MenuData`);
                        break;
                    case getEnv("PIM"):
                        element = await import(`modules/pim/MenuData`);
                        break;
                    default:
                        console.warn(`Module ${m} not found!`);
                        continue;
                }
                
                if (element?.Menu) {
                    element.Menu.forEach((menuItem: IMenuItem) => items.push(menuItem));
                }

                // if (element?.Breadcrumbs) {
                //     combinedBreadcrumbs = {
                //         ...combinedBreadcrumbs,
                //         ...mapArrayToBreadcrumbMap(element.Breadcrumbs),
                //     };
                // }
            } catch (e) {
                console.error(`Error loading module ${m}:`, e);
            }
        }
        setModuleMenu(items);
        // setBreadcrumbs(combinedBreadcrumbs);
    };

    const mapArrayToBreadcrumbMap = (breadcrumbsArray: { path: string; label: string }[]) => {
        return breadcrumbsArray.reduce((acc, curr) => {
            acc[curr.path] = curr.label;
            return acc;
        }, {} as { [key: string]: string });
    };

    return { moduleMenu, breadcrumbs };
};