import React from 'react';
import { LogoutRounded } from '@mui/icons-material';
import { Avatar, Box, Divider, IconButton, Typography } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { useProfileContext } from 'contexts/ProfileContext';
import { useSignOut } from 'core/hooks/useSignOut';
import { getTokenOrDefault, resetStorage } from 'helpers/Storage';

const Profile: React.FC = () => {
    const { profile } = useProfileContext();
    const { signout } = useSignOut();
    const navigate = useNavigate();

    const handleLogout = async () => {
        const token = getTokenOrDefault();
        await signout(token);
        resetStorage();
        navigate("/sign-in");
    };

    const getInitials = (firstName: string, lastName: string) => {
        return `${firstName[0]}${lastName[0]}`.toUpperCase();
    };

    const handleAvatarClick = () => {
        navigate('/my-profile');
    };

    return (
        <React.Fragment>
            <Divider />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Avatar
                    variant="outlined"
                    size="sm"
                    src={profile?.image_url as string}
                    onClick={handleAvatarClick}
                    sx={{
                        cursor: 'pointer',
                        backgroundColor: profile?.image_url !== null ? '#4299E1' : 'transparent',
                        color: profile?.image_url !== null ? '#fff' : 'inherit',
                        fontSize: { xs: '16px', sm: '16px', md: '18px' },
                    }}
                >
                    {profile?.image_url === null ? getInitials(profile.first_name, profile.last_name) : null}
                </Avatar>
                <Box sx={{ minWidth: 0, flex: 1 }}>
                    <Typography level="title-sm">
                        {profile !== null ? `${profile?.first_name} ${profile?.last_name[0]}.` : '---'}
                    </Typography>
                    <Typography level="body-xs">
                        {profile !== null ? profile?.email_address : '---'}
                    </Typography>
                </Box>
                <IconButton size="sm" variant="plain" color="neutral" onClick={handleLogout}>
                    <LogoutRounded />
                </IconButton>
            </Box>
        </React.Fragment>
    );
};

export default Profile;
