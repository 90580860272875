import { useContext, useState, createContext } from "react";

export interface IApp {
    application: string|null,
    version: string|null,
    country_iso_code: string|null,
    currency_iso_code: string|null,
    language_iso_code: string|null,
    timezone: string|null,
}

export const AppContext = createContext<IApp|null>(null);

export default function AppContextProvider({children}:any) {
    const [app] = useState<IApp|null>(null);

    return (
        <AppContext.Provider value={app}>
            {children}
        </AppContext.Provider>
    )
}

export const useAppContext = () => {
    const context = useContext(AppContext);
    if(!context) {
        throw new Error(
            "useAppContext must be used within a AppContextProvider"
        );
    }
    return context;
}