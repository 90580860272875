import { createContext, useContext, useState } from "react";

export interface IProfile {
    account_id: string,
    user_id: string,
    first_name: string,
    middle_name: string | null,
    last_name: string,
    birth_date: string | null,
    gender: string,
    image_url: string | null,
    email_address: string,
    phone_number: string,
    custom_attributes: string | null,
    is_force_update: boolean,
    role: string,
    created_at: string
}

export type IProfileContext = {
    profile: IProfile | null,
    setProfile: Function
}

export const ProfileContext = createContext<IProfileContext>({
    profile: null,
    setProfile: () => { }
});

export default function ProfileContextProvider({ children, profile, setProfile }: any) {

    return (
        <ProfileContext.Provider value={{ profile, setProfile }}>
            {children}
        </ProfileContext.Provider>
    )
}

export const useProfileContext = () => {
    const context = useContext(ProfileContext);
    if(!context) {
        throw new Error(
            "useProfileContext must be used within a ProfileContextProvider"
        );
    }
    return context;
}