import { NotificationsRounded } from '@mui/icons-material';
import { Drawer, IconButton } from '@mui/joy';
import React, { useState } from 'react'

const Notifications = () => {
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <IconButton
                onClick={() => setOpen(true)}
                variant="soft"
                color="neutral"
                size="sm"
            >
                <NotificationsRounded />
            </IconButton>

            <Drawer 
                open={open} 
                onClose={() => {setOpen(false)}}
                variant="soft"
                anchor="right"
            >

            </Drawer>
        </React.Fragment>
    )
}

export default Notifications