import { GlobalStyles, Sheet, Theme } from '@mui/joy';
import React from 'react'
import SidebarOverlay from './includes/SidebarOverlay';
import Brand from './includes/Brand';
import Profile from './includes/Profile';
import Menu from './menu/Index';

const sidebarSx = {
    position: { xs: 'fixed', md: 'sticky' },
    transform: {
        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
        md: 'none',
    },
    transition: 'transform 0.4s, width 0.4s',
    zIndex: 10000,
    height: '100dvh',
    width: 'var(--Sidebar-width)',
    top: 0,
    p: 2,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    borderRight: '1px solid',
    borderColor: 'divider',
}

const globalSx = (theme: Theme) => ({
    ':root': {
        '--Sidebar-width': '220px',
        [theme.breakpoints.up('lg')]: {
            '--Sidebar-width': '240px',
        },
    },
});

const Sidebar = () => {
    return (
        <Sheet className="Sidebar" sx={sidebarSx}>
            <GlobalStyles styles={globalSx} />
            <SidebarOverlay />
            <Brand />
            <Menu />
            <Profile />
        </Sheet>
    );
};

export default Sidebar