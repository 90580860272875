import { refreshToken } from 'actions/security'

export const useRequestToken = () => {
    const getToken = async () => {
        const response = await refreshToken();
        if(response.is_success) {
            return response.data as string;
        }
        return null;
    }
    return { getToken }
}