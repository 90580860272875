import { getProfile } from "actions/user";
import { IProfile } from "contexts/ProfileContext"

export const useProfile = () => {
    const fetchProfile = async (token: string) => {
        const result = await getProfile(token);
        if(result.is_success && result.data !== null) {
            return result.data as IProfile
        }
        return null;
    }

    return { fetchProfile }
}