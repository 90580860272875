import { getModules } from 'actions/security';

export const useModuleRoutes = () => {

    const initModules = async (token: string) => {
        const result = await getModules(token)
        if(result.is_success && result.data !== null) {
            return JSON.stringify(result.data);
        }
        return null;
    }

    return { initModules }
}