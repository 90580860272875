import Loadable from 'components/Loadable'
import React, { lazy } from 'react'
import PublicLayout from 'views/layout/public/Index'

const SignIn = Loadable(lazy(() => import("views/pages/public/SignIn")));
const SignUp = Loadable(lazy(() => import("views/pages/public/SignUp")));
const Verify = Loadable(lazy(() => import("views/pages/public/Verify")));
const Offline = Loadable(lazy(() => import("views/pages/public/Offline")))

const PublicRoutes =  {
    path: "/",
    element: <PublicLayout/>,
    children: [
        {
            path: "sign-in",
            element: <SignIn/>
        },
        {
            path: "sign-up",
            element: <SignUp/>
        },
        {
            path: "verify/:token",
            element: <Verify/>
        },
        {
            path: "offline",
            element: <Offline/>
        }
    ]

}

export default PublicRoutes