import React, { useEffect, useState } from 'react'
import { useRoutes } from 'react-router-dom'
import PublicRoutes from 'routes/PublicRoutes';
import PrivateRoutes from 'routes/PrivateRoutes';
import { getEnv } from 'helpers/Env';
import { getItem, getModulesOrDefault } from 'helpers/Storage';

const defaultRoutes = [PublicRoutes, PrivateRoutes];

const AppRoutes = () => {
    const [newRoutes, setNewRoutes] = useState<any[]>(defaultRoutes);

    useEffect(() => {
        importRoutes();
    }, []);

    const importRoutes = async () => {
        const nr = [...newRoutes];
        const modules = [];
        const routes: string[] = getModulesOrDefault();

        for (let m of routes) {
            let element: any;

            switch (m) {
                case getEnv("PIM"):
                    element = await import("modules/pim/PIMRoutes");
                    break;
                case getEnv("ERS"):
                    element = await import("modules/ers/ERSRoutes");
                    break;
                default:
                    break;
            }

            if(typeof(element) !== "undefined") {
                nr.push(element.default)
                modules.push(m);
            }
        }
        setNewRoutes(nr);
    };

    return useRoutes(newRoutes);
}

export default AppRoutes
