import { LinearProgress, styled } from '@mui/joy'
import React from 'react'

const ProgressWrapper = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 2001,
    width: '100%',
    '& > * + *': {
        marginTop: theme.spacing(2)
    }
}));

const Progress = () => (
    <ProgressWrapper>
        <LinearProgress color='primary' />
    </ProgressWrapper>
)

export default Progress;