import App from 'core/App';
import React from 'react'
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<BrowserRouter basename='/'>
			<App/>
		</BrowserRouter>
	</React.StrictMode>
);
