import { lazy } from "react";
import Loadable from "components/Loadable";
import MainLayout from "views/layout/private/Index";

const Dashboard = Loadable(lazy((() => import("views/pages/private/Dashboard"))));
const Support = Loadable(lazy((() => import("views/pages/private/Support/Support"))));
const Settings = Loadable(lazy((() => import("views/pages/private/Settings/Settings"))));

const PrivateRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
        {
            path: "dashboard",
            element: <Dashboard />
        },
        {
            path: "support",
            element: <Support />
        },
        {
            path: "settings",
            element: <Settings />
        }
    ]
};

export default PrivateRoutes;
