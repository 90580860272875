import React, { useEffect } from 'react';
import { useAppInit } from './hooks/useAppInit';
import AppRoutes from 'routes';
import { SnackbarProvider } from 'notistack';
import AppContextProvider from 'contexts/AppContext';
import ProfileContextProvider from 'contexts/ProfileContext';

const Wrapper = () => {
    const { app, profile, setProfile, loading } = useAppInit();

    return (
        <SnackbarProvider
            maxSnack={3}
            style={{ zIndex: 9999 }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
        >
            <AppContextProvider value={app}>
                {!loading ? (<ProfileContextProvider profile={profile} setProfile={setProfile}>
                    <AppRoutes />
                </ProfileContextProvider>) : null}
            </AppContextProvider>
        </SnackbarProvider>
    )
}

export default Wrapper