import { ChevronRightRounded, HomeRounded } from '@mui/icons-material';
import { Box, Breadcrumbs as JCrumbs, Link, Typography } from '@mui/joy';
import { Link as RLink, useLocation } from 'react-router-dom';
import { useModuleMenu } from '../../../core/hooks/useModuleMenu'; 
import React from 'react';

const Breadcrumbs = () => {
  const location = useLocation();
  const { breadcrumbs } = useModuleMenu();
  const pathnames = location.pathname.split('/').filter(x => x); 

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <JCrumbs
        size="sm"
        aria-label="breadcrumbs"
        separator={<ChevronRightRounded fontSize="small" />}
        sx={{ pl: 0 }}
      >
        <Link
          component={RLink}
          underline="none"
          color="neutral"
          to="/dashboard" 
          aria-label="Home"
        >
          <HomeRounded />
        </Link>

        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;

          const breadcrumbLabel = breadcrumbs[to] || breadcrumbs[`/${value}`] || value.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase());

          const isLast = index === pathnames.length - 1;
          
          return isLast ? (
            <Typography
              key={to}
              color="primary"
              fontWeight={500}
              fontSize={12}
            >
              {breadcrumbLabel}
            </Typography>
          ) : (
            <Link
              key={to}
              component={RLink}
              underline="hover"
              color="neutral"
              to={to}
              fontSize={12}
              fontWeight={500}
            >
              {breadcrumbLabel}
            </Link>
          );
        })}
      </JCrumbs>
    </Box>
  );
};

export default Breadcrumbs;
