import React from 'react'
import { KeyboardArrowDown } from '@mui/icons-material'
import { Divider, List, ListItem, ListItemButton, ListItemContent, Typography } from '@mui/joy'
import Toggler from 'components/common/Toggler'
import { IMenuItem } from 'interfaces/IMenu'
import { Link } from 'react-router-dom'

const RenderMenu = ({ menu }: { menu: IMenuItem }) => {
    return menu.children?.length > 0 ? <NestedItem menu={menu} /> : <MenuItem menu={menu} />
}

const MenuItem = ({ menu }: { menu: IMenuItem }) => {
    return (
        <React.Fragment>
            {menu.divider ? <Divider /> : null}
            <ListItem>
                <ListItemButton component={Link} to={menu.path}>
                    {menu.icon}
                    <ListItemContent>
                        <Typography level="title-sm">
                            {menu.name}
                        </Typography>
                    </ListItemContent>
                </ListItemButton>
            </ListItem>
        </React.Fragment>
    )
}

const NestedItem = ({ menu }: { menu: IMenuItem }) => {
    return (
        <React.Fragment>
            {menu.divider ? <Divider /> : null}
            <ListItem nested>
                <Toggler renderToggle={({ open, setOpen }) => (
                    <ListItemButton onClick={() => setOpen(!open)}>
                        {menu.icon}
                        <ListItemContent>
                            <Typography level="title-sm">
                                {menu.name}
                            </Typography>
                        </ListItemContent>
                        <KeyboardArrowDown
                            sx={[
                                open
                                    ? {
                                        transform: 'rotate(180deg)',
                                    }
                                    : {
                                        transform: 'none',
                                    },
                            ]}
                        />
                    </ListItemButton>
                )}
                >
                    <List sx={{ gap: 0.5 }}>
                        {menu.children.map((sm: IMenuItem, i:number) => (<MenuItem menu={sm} key={`${sm.id}_${i}`} />))}
                    </List>
                </Toggler>
            </ListItem>
        </React.Fragment>
    )
}

export default RenderMenu