import { GlobalStyles, IconButton, Sheet } from '@mui/joy'
import React from 'react'
import { toggleSidebar } from './utils'
import MenuIcon from '@mui/icons-material/Menu'
import { NotificationsRounded } from '@mui/icons-material'
import Notifications from './Notifications'

const Header = () => {
    return (
        <Sheet
            sx={{
                display: { xs: 'flex', md: 'flex' },
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'fixed',
                top: 0,
                width: '100vw',
                height: { xs: 'var(--Header-height)', md: '65px'},
                zIndex: 9995,
                p: 2,
                gap: 1,
                borderBottom: '1px solid',
                borderColor: 'background.level1',
                boxShadow: 'sm',
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ':root': {
                        '--Header-height': '52px',
                        [theme.breakpoints.up('md')]: {
                            '--Header-height': '0px',
                        },
                    },
                })}
            />

            <IconButton
                onClick={() => toggleSidebar()}
				variant="outlined"
				color="neutral"
				size="sm"
            >
                <MenuIcon />
            </IconButton>

            <Notifications/>
        </Sheet>
    )
}

export default Header